import axios from "axios";
import { ManageLocalStorage } from "./LocalStorage"
import {ManageSessionStorage} from './SessionStorage'
import { APP_TOKEN, USER_TOKEN } from '../common/Constants'

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";


export const httpCall = async payload => {
  let config = {};
  let token = ManageLocalStorage.get(APP_TOKEN) ? ManageLocalStorage.get(APP_TOKEN) : "";
  if (false) {
    config.headers = { 'Application-Token': token, 'Content-Type': 'application/json' };
  }
  else {
    // config.headers = { 'Application-Token': token, 'Content-Type': 'application/json' };
    config.headers = { 'Content-Type': 'application/json' };
  }

  config.method = payload.method ? payload.method : "get";
  config.url = payload.url ? payload.url : "";
  if (payload.data) {
    config.data = payload.data;
  }
  return axios(config);
}

export const httpCallNormal = async payload => {
  let config = {};
  config.method = payload.method ? payload.method : "get";
  config.url = payload.url ? payload.url : "";
  if (payload.data) {
    config.data = payload.data;
  }
  return axios(config);
}

export const httpLoginCall = async payload => {
  let config = {};
  let token = ManageLocalStorage.get(APP_TOKEN) ? ManageLocalStorage.get(APP_TOKEN) : "";
  config.headers = { 'Application-Token': token, 'Content-Type': 'application/json' };
  config.method = payload.method ? payload.method : "get";
  config.url = payload.url ? payload.url : "";
  if (payload.data) {
    config.data = payload.data;
  }
  return axios(config);
}

export const httpGetPublicSolana = async payload => {
  let config = {};
  let token = ManageLocalStorage.get("token")
    ? ManageLocalStorage.get("token")
    : "";
  config.headers = { Token: token };
  config.method = payload.method ? payload.method : "get";
  config.url = payload.url ? payload.url : "";
  if (payload.data) {
    config.params = payload.data;
  }
  return axios(config);
}


export const httpFormCall = async payload => {
  let config = {};
  let token = ManageLocalStorage.get(APP_TOKEN) ? ManageLocalStorage.get(APP_TOKEN) : "";
  let user_token = ManageLocalStorage.get(USER_TOKEN) ? ManageLocalStorage.get(USER_TOKEN) : "";
  if (user_token) {
    config.headers = { 'Application-Token': token, 'Authorization': user_token, 'Content-Type': 'application/x-www-form-urlencoded' };
  }
  else {
    config.headers = { 'Application-Token': token, 'Content-Type': 'application/x-www-form-urlencoded' };
  }

  config.method = payload.method ? payload.method : "get";
  config.url = payload.url ? payload.url : "";
  if (payload.data) {
    const _urlEncodedData = Object.keys(payload.data)
      .map((key, index) => `${key}=${encodeURIComponent(payload.data[key])}`)
      .join('&');
    payload.data = _urlEncodedData;
    config.data = payload.data;
  }
  return axios(config);
}


export const httpUpload = (payload) => {
  let config = {};
  let token = ManageLocalStorage.get("token")
    ? ManageLocalStorage.get("token")
    : "";
  config.headers = {
    token: token,
    "Content-Type": "multipart/form-data"
  };
  config.method = "post";
  config.url = payload.url ? payload.url : "";
  config.data = payload.data;
  //if (payload.data) config.data = JSON.stringify(payload.data);
  return axios(config);
}

export const setBaseUrl = (baseUrl) => {
  console.log("_BASE_URL :", baseUrl);
  axios.defaults.baseURL = baseUrl;
}

export const setApplicationToken = (token) => {
  ManageLocalStorage.set(APP_TOKEN, token);
}

export const getBaseUrl = () => {
  return axios.defaults.baseURL
}


export const httpInterceptor = () => {

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {

      let _error_status_code = (error.request.status == 0) ? 401 : error.request.status;

      if (_error_status_code === 401) {
        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
        window.location.reload();
      } else {
        return error.response;
      }
    }
  );

}