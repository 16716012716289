import React, { useState, useEffect } from 'react';
import './LandGrabPreSale.css'; // Make sure your CSS file is correctly referenced
import SolanaConnectButton from './wallet_adapter/CustomConnectButton'
// import MySolanaButton from './wallet_adapter/MySolanaButton'
import {verifyWallet, executeOrder, logPaymentFailure} from './LandGrabPreSaleService';
import {setBaseUrl, setApplicationToken } from './core/HttpService';
import Toast from './components/toast/Toast';
import { TOAST_PROPERTIES } from './components/toast/toastProperties';
// import {makePayment} from './wallet_adapter/PaymentManager';
import {COST_PER_PLOT} from './common/Constants';
import CustomPaymentButton from './components/pay_button/PaymentButton';

// LandPlotSelector component now receives props
const LandPlotSelector = ({ landPlots, setLandPlots, maxAllowedPlots }) => {
  const costPerPlot = COST_PER_PLOT; // The cost per land plot in SOL
  
  // Update incrementPlots to limit to 10
  const incrementPlots = () => {
    if (landPlots < maxAllowedPlots) {
      setLandPlots(landPlots + 1);
    }
  };

  // Update decrementPlots to limit to 1
  const decrementPlots = () => {
    if (landPlots > 1) {
      setLandPlots(landPlots - 1);
    }
  };

  const totalCost = landPlots * costPerPlot;

  return (
    <div className="land-plot-selector-container">
      <label htmlFor="num-plots" className="land-plot-label">Number of Gizmos:</label>
      <div className="land-plot-selector">
        <button onClick={decrementPlots} aria-label="Decrease">-</button>
        <span id="num-plots">{landPlots}</span>
        <button onClick={incrementPlots} aria-label="Increase">+</button>
      </div>
      <div className="total-cost">Total: {totalCost.toFixed(2)} SOL</div>
    </div>
  );
};


// LandGrabPreSale component that manages landPlots state
const LandGrabPreSale = () => {
  const [landPlots, setLandPlots] = useState(2); // State for the number of land plots
  const [maxOrder, setMaxOrder] = useState(10); // State for the number of available orders
  const [maxAvailable, setMaxAvailable] = useState(10); // State for the total number of allocated orders
  const [currentOrder, setCurrentOrder] = useState(0); // State for the selected number of orders
  const [canPay, setCanPay] = useState(false);
  const [showWalletLoader, setShowWalletLoader] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [list, setList] = useState([]);
  const [walletAddress, setWalletAddress] = useState('');
  const [solanaWalletConnected, setSolanaWalletConnected] = useState(null);
  // const toast = useRef(null);
 
  useEffect(() => {
    console.log(`API BASE URL : `, process.env.REACT_APP_API_BASE_URL);

    setBaseUrl(process.env.REACT_APP_API_BASE_URL);
    setApplicationToken(process.env.REACT_APP_APP_TOKEN);
    // httpInterceptor();
    console.log(`Basic setup DONE`);
  }, []);

  const showToast = (type, message) => {
    let toastProperties = TOAST_PROPERTIES.find((toast) => toast.title.toLowerCase() === type);
    toastProperties.description = message;
    setList([...list, toastProperties]);
  }

  const showSuccess = (message) => {
    // toast.current.show({ severity: 'success', summary: 'Success', detail: `${message}`, life: 3000 });
    showToast('success', message);
  }

  const showError = (errormsg) => {
    // toast.current.show({ severity: 'error', summary: 'Error', detail: `${errormsg}`, life: 3000 });
    showToast('error', errormsg);

  }
/*
  // Check Code Button handler
  const checkCode = async () => {
    // Logic for checking the code goes here
    if (!inviteCode || inviteCode.length <= 0) {
      showError("Please enter a valid invite code");
      return;
    }
    let data = {
      code: inviteCode
    }
    try {
      //TODO : Show some loader while this is happening
      let respWrapper = await verifyInviteCode(data);
      let resp = respWrapper.data;
      console.log(`Verification Response : `, resp.data);
      setcodeVerificationStatus(resp.success);
      if (resp.success) {
        showSuccess(resp.message);
      }
      else {
        showError(resp.error);
      }
    } catch (error) {
      console.log(`Code Verification Error is : `, error.message);
    }

  };


  // Join Whitelist Button handler
  const joinWhitelist = async () => {
    // Logic for joining the whitelist goes here
    if (walletAddress && walletAddress.length > 0) {
      let data = {
        wallet: walletAddress,
        chain: 'SOL'
      }
      try {
        //TODO : Show some loader while this is happening
        let respWrapper = await addToWhitelist(data);
        let resp = respWrapper.data;
        console.log(`Whitelist Response : `, resp.data);
        if (resp.success) {
          showSuccess(resp.message);
        }
        else {
          showError(resp.error);
        }
      } catch (error) {
        console.log(`Whitelist Error is : `, error.message);
        showError(error.message);
      }
    }
    else {
      showError('Please connect your wallet first');
      return;
    }
  };
*/

  // Follow Us Button handler
  const followUs = () => {
    window.open('https://twitter.com/playgizmos', '_blank', 'noopener,noreferrer');
  };


  // Handler for payment logic
  const handlePayment = async (paymentSuccess, price, message, senderWallet, the_signature, transaction_id) => {
    // Set payment as confirmed for demonstration purposes
    if (paymentSuccess) {
      console.log(`Transaction completed successfully`);
      //TODO - Update DB
      // if (walletAddress && walletAddress.length > 0 && codeVerificationStatus && inviteCode && inviteCode.length > 0) {
        let data = {
          wallet_address: senderWallet,
          quantity: landPlots,
          price: landPlots * COST_PER_PLOT,
          transaction_id: transaction_id,
          signature: the_signature
        }
        console.log(`Transaction details`);
        console.log(`Original wallet address property : `,walletAddress);
        
        try {
          //TODO : Show some loader while this is happening
          let respWrapper = await executeOrder(data);
          let resp = respWrapper.data;
          console.log(`Execute Order Response : `, resp.data);
          if (resp.success) {
            showSuccess(resp.message);
            setPaymentConfirmed(true);
          }
          else {
            showError(resp.error);
          }
        } catch (error) {
          console.log(`Execute order Error is : `, error.message);
          showError(error.message);
        }
      // }
      // else {
      //   showError('Please connect your wallet first');
      //   return;
      // }
    }
    else {
      console.log(`Transaction failed ...`);

      showError(message ? message : "Payment failed. Please retry")
    }
  };
  
  const logFailure = async (response) => {
    console.log(`Logging payment failure.............................`);
    
    // Log payment failure with back end
    let data = {
      wallet: walletAddress,
      quantity: landPlots,
      price: landPlots * COST_PER_PLOT,
      metadata: response
    }
    try {
      //TODO : Show some loader while this is happening
      let respWrapper = await logPaymentFailure(data);
      let resp = respWrapper.data;
      console.log(`Logged Order Failure : `, resp.data);
    } catch (error) {
      console.log(`logFailure Error is : `, error.message);
    }
    
  };

  const onSolanaWalletConnection = async (connected, address) => {
    // setChainType(BlockChainType.SOL);
    console.log(`Solana Wallet connection status : `, connected);
    console.log(`Solana Wallet address : `, address);
    setSolanaWalletConnected(connected);
    if (!connected) {
      address = undefined;
    }
    setWalletAddress(address);
    if (address) {
      //TODO: Once wallet is connected, verify wallet status and set order.
      //TODO : Show some loader while this is happening
      // address = "Do9VaY2ru9QtaTWdhmuwc2Z9rj4rZ7D6EShdLTvxnp6h";//FIXME 
      setShowWalletLoader(true);
      let respWrapper = await verifyWallet(address);
      setShowWalletLoader(false);
      let resp = respWrapper.data;
      console.log(`Verify Wallet Response : `, resp.data);
      if (resp.success) {
        let _maxbuy = resp.data.available;
        let _maxAllocation = resp.data.max_allocation;
        let _currentlyInventory = resp.data.inventory;
        setMaxOrder(_maxbuy);
        setMaxAvailable(_maxAllocation);
        setCurrentOrder(_currentlyInventory);
        setLandPlots(_maxbuy);
        setCanPay(connected);
      }
    }
    else {
      setMaxOrder(0);
      setCanPay(false);
    }
  }


  return (
    <div className="app">
      {/* <Toast ref={toast} position="top-right" /> */}
      <Toast 
        toastList={list}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3000}
      />
      {!paymentConfirmed ? (
        <div className="pre-sale-container">
          <h1>GIZMOS PRE-SALE</h1>
          <SolanaConnectButton state={solanaWalletConnected} setData={onSolanaWalletConnection} />
          {/* <MySolanaButton state={solanaWalletConnected} setData={onSolanaWalletConnection} /> */}
          {canPay && <div className="whitelist-line"></div>}
          {canPay && <h3> Max allocation : {maxAvailable}</h3>}
          {canPay && <h3> Presales bought : {currentOrder}</h3>}
          {canPay && <h3> Available allocation : {maxOrder}</h3>}
          {/* {canPay && <h3> You currently own {currentOrder} Gizmos</h3>} */}
          <div className="whitelist-line"></div>
          {canPay && < LandPlotSelector landPlots={landPlots} setLandPlots={setLandPlots} maxAllowedPlots={maxOrder}/> }
          {canPay && <div className="whitelist-line"></div>}
          {!canPay && !showWalletLoader && <h3> Please connect your wallet to place order</h3>}
          {showWalletLoader && <h3> Verifying wallet....</h3>}
          {/* <button onClick={handlePayment} className="paynow-button">
            PAY NOW
          </button> */}
          { canPay && <CustomPaymentButton  landPlots = {landPlots}  onPaymentCompletion={handlePayment} onPaymentFailure={logFailure}/> }
        </div>
      ) : (
        <div className="congratulations-container">
          <h1>Congratulations!</h1>
          { (landPlots > 1) ? <h2>You have pre-bought {landPlots} Gizmos Scouts.</h2> : <h2>You have pre-bought a Gimzos Scout</h2>}
          <h3>Your Gizmos Scouts will be airdropped to your wallet once we start our public sale. Make sure to join our Discord and follow our Twitter (X) to stay updated.</h3>
          <button onClick={followUs} className="follow-button">
            Follow Us on Twitter
          </button>
        </div>
      )}
    </div>
  );
};


export default LandGrabPreSale;


