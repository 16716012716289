export const APP_TOKEN = "Application_Token"
export const USER_TOKEN = "User_Token"
export const API_URL = {
    VERIFY_WALLET: "/v1/presale/wallet/info",
    INITIATE_ORDER: "/v1/presale/order/initiate",
    EXECUTE_ORDER: "/v1/presale/order/execute",
    PLACE_ORDER: "/v1/presale/order/place",
    LOG_FAILED_ORDER: "/v1/presale/order/log_failure"
}
/**Amount of SOL per land plot */
export const COST_PER_PLOT = 0.8;