import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Transaction, SystemProgram, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import React, { useCallback,useState} from "react";
import { COST_PER_PLOT } from "../../common/Constants";
import {initiateOrder} from '../../LandGrabPreSaleService';

/**
 * Component that takes care of SOL transfer to owner wallet
 * @param {number} amount 
 * @returns A Button component that user can use to initiate payment
 */
export const CustomPaymentButton = ({ landPlots, onPaymentCompletion, onPaymentFailure}) => {
    const { connection } = useConnection();
    // const connection = new Connection(clusterApiUrl("testnet"))
    const { publicKey, sendTransaction } = useWallet();
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const onClick = useCallback(async () => {
        setPaymentInProgress(true);
        let success = false;
        let message = "";
        let amount = Number(landPlots * COST_PER_PLOT);
        amount = Math.round(amount * 100) / 100;
        let the_signature;
        let transaction_id;
        try {
            if (!publicKey) throw new WalletNotConnectedError();
            
            //Verify order with back-end, before proceeding with payment
            let order_data = {
                wallet: publicKey.toString(),
                quantity: landPlots,
                price: amount
            }
            let order_verification_result = await initiateOrder(order_data);
            let order_verification_resp = order_verification_result.data;
            console.log(`Order verification response : `,order_verification_resp);
            if (!order_verification_resp.success) throw new Error(order_verification_resp.error);
            
            console.log(publicKey.toBase58());
            console.log(`Address is : `, publicKey.toString());

            // let balance = await connection.getBalance(publicKey);
            // console.log(`Balance is `, (balance / LAMPORTS_PER_SOL));
            
            console.log(`Number of selected landPlots : `, landPlots);
            console.log(`Amount in SOL : `, amount);
            
            let lamportsI = LAMPORTS_PER_SOL * amount;
            console.log("lamports sending: {}", lamportsI);

            let tx_base = order_verification_resp.data.tx_base;
            transaction_id = order_verification_resp.data.transaction_id;
            const transaction = Transaction.from(Buffer.from(tx_base, 'base64')); 
            /* Now transaction is being created from the back-end
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey(process.env.REACT_APP_RECEIVER_WALLET),
                    lamports: lamportsI,
                })
            );
            */

            const signature = await sendTransaction(transaction, connection);
            the_signature = signature;
            const latestBlockHash = await connection.getLatestBlockhash()

            let rr = await connection.confirmTransaction({
                blockhash: latestBlockHash.blockhash,
                lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
                signature: signature,
            });
            // console.log(`rr.value :`, rr.value);
            if (rr.value.confirmationStatus === 'confirmed' || rr.value.confirmationStatus === 'processed' || rr.value.confirmationStatus === 'finalized' || !rr.value.err) {
                success = true;
                console.log(`Successful transaction 1`);
            }
            else {
                //TODO: Log Failure
                onPaymentFailure(rr);
                message = "Something went wrong.";
            }
            console.log(`Transaction response : `, rr);

        } catch (error) {
            console.log("🚀 ~ Payment ~ error:", error)
            if (error.name  === 'WalletNotConnectedError') {
                message = 'Please connect your wallet first';
            }
            else {
                message = error.message;
            }
            setPaymentInProgress(false);
            onPaymentFailure(error);
        }
        setPaymentInProgress(false);
        onPaymentCompletion(success, amount, message, publicKey?.toString(), the_signature, transaction_id);

    }, [publicKey, sendTransaction, connection, landPlots,onPaymentCompletion, onPaymentFailure]);

    return (
        <button onClick={onClick} className="paynow-button" >
            {paymentInProgress &&
                  <i className="pi pi-spin pi-spinner" style={{ marginRight: '10px' }}></i>
            }
            PAY NOW
        </button>
    );
};

export default CustomPaymentButton