import React from 'react';
import LandGrabPreSale from './LandGrabPreSale';
import './App.css'; // This is where you would import global styles
import logo from './assets/whitegizmo.png'; // Adjust the path as necessary
import {useMemo} from "react"
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
// import the styles
require('@solana/wallet-adapter-react-ui/styles.css');

const App = () => {
  let endpoint = process.env.REACT_APP_SOLANA_RPC_URL;
  let solNetwork = process.env.REACT_APP_SOLANA_RPC_URL
  if (process.env.REACT_APP_MY_ENV === 'LOCAL' || process.env.REACT_APP_MY_ENV === 'STAGING') {
    // Use Solana devnet for local testing
    solNetwork = WalletAdapterNetwork.Devnet;
    endpoint = clusterApiUrl(solNetwork);
  }
  // you can use Mainnet, Devnet or Testnet here
  // const solNetwork = WalletAdapterNetwork.Devnet;
  // const solNetwork = WalletAdapterNetwork.Mainnet;
  // const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);

  // const solNetwork = process.env.REACT_APP_SOLANA_RPC_URL;
  // const endpoint = solNetwork;
  // initialise all the wallets you want to use
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      // new GlowWalletAdapter(),
      // new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ solNetwork }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      // new SolletExtensionWalletAdapter(),
      // new SolletWalletAdapter(),
    ],
    [solNetwork]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={true}>
        <WalletModalProvider>
          <div className="App">
            <img src={logo} alt="Logo" className="app-logo" />
            <LandGrabPreSale />
            <footer className="app-footer">
              © 2023 Powered by Interverse
            </footer>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider >
  );
}


export default App;
