import { httpCall } from "./core/HttpService"
import { API_URL } from "./common/Constants"

export const verifyWallet = async (wallet_address) => {
    let theURL = `${API_URL.VERIFY_WALLET}?wallet=${wallet_address}`
    const response = await httpCall({
        url: theURL,
        method: "get"
    });
    return response;
};
export const initiateOrder = async params => {
    const response = await httpCall({
        url: API_URL.INITIATE_ORDER,
        method: "post",
        data: params
    });
    return response;
};
export const logPaymentFailure = async params => {
    const response = await httpCall({
        url: API_URL.LOG_FAILED_ORDER,
        method: "post",
        data: params
    });
    return response;
};
export const executeOrder = async params => {
    const response = await httpCall({
        url: API_URL.EXECUTE_ORDER,
        method: "post",
        data: params
    });
    return response;
};
export const placeOrder = async params => {
    const response = await httpCall({
        url: API_URL.PLACE_ORDER,
        method: "post",
        data: params
    });
    return response;
};

